import React from "react"
import tw, { styled } from "twin.macro"
import _ from "lodash"
import moment from "moment-timezone"
import { getContrastText } from "../utils"

const EventContainer = styled.div`
  ${tw`flex flex-col w-full flex-1 h-full relative p-1 justify-center items-center text-center`}
  background-color: ${props => props.backgroundColor};
  color: ${props => getContrastText(props.backgroundColor)};
`

const EventName = styled.div`
  ${tw`text-xs font-bold`}
`

const CalendarEventItem = ({ event }) => {
  const { name, highlightColor } = event.node.frontmatter

  return (
    <EventContainer backgroundColor={highlightColor}>
      <EventName>{name}</EventName>
    </EventContainer>
  )
}

export default CalendarEventItem
