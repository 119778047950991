import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import React, { useState } from "react"
import tw, { styled } from "twin.macro"
import _ from "lodash"
import { getContrastText } from "../utils"
import { Popover } from "react-tiny-popover"
import useStats from "../hooks/useStats"

const GameContainer = styled.div`
  ${tw`flex flex-col w-full flex-1 h-full relative p-1`}
  box-shadow: 
    -1px 0 0 0 #999999, 
    0 -1px 0 0 #999999, 
    1px 0 0 0 #999999, 
    0 1px 0 0 #999999;
  background-color: ${props => props.backgroundColor};
  color: ${props => getContrastText(props.backgroundColor)};
  ${({ striped, backgroundColor, promoColor }) =>
    striped &&
    `background: repeating-linear-gradient(135deg, ${promoColor},${promoColor} 10px,${backgroundColor} 10px,${backgroundColor} 20px);`}
`
const LogoContainer = styled.div`
  ${tw`w-10 h-8`}
  ${({ noTopMargin }) => (noTopMargin ? tw`mt-0 mr-1` : tw`mx-auto mt-2`)}
`
const GameTextLink = styled(Link)`
  ${tw`font-bold text-sm text-center hover:underline mx-auto text-ellipsis overflow-hidden whitespace-nowrap`}
  width: ${({ width }) => `${width}px`};
`
const GameText = styled.div`
  ${tw`font-bold text-sm text-center hover:underline mx-auto text-ellipsis overflow-hidden whitespace-nowrap`}
  width: ${({ width }) => `${width}px`};
`
const GameTime = styled.div`
  ${tw`text-center text-xs mt-1`}
`
const GameActionContainer = styled.div`
  ${tw`flex flex-col mt-auto mb-1`}
`
const GameLink = styled.a`
  ${tw`block relative text-center bg-black text-white mx-1 rounded py-0.5 font-bold text-xs uppercase`}
`

const PopOverContent = styled.div`
  ${tw`w-[240px] h-[240px] border border-black`}
`
const MediaContainer = styled.div`
  ${tw`text-sm mx-2`}
`

const DescriptionContainer = styled.div`
  ${tw`text-xs pb-1 overflow-y-auto`}
  scrollbar-width: thin;
  scrollbar-color: #cfcfcf;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cfcfcf;
    border-radius: 10px;
    border: 1px solid #cfcfcf;
  }
`
const DescriptionHeader = styled.div`
  ${tw`flex text-xs font-bold items-center mt-2 ml-2`}
`
const DescriptionList = styled.ul`
  ${tw`py-0.5`}
`
const DescriptionItem = styled.li`
  ${tw`ml-7 text-[10]`}
`
const PopoverGameLink = styled.a`
  ${tw`flex justify-center bg-black text-white mx-1 rounded py-1 font-bold text-sm uppercase`}
`

const PopoverLogoContainer = styled.div`
  ${tw`w-14 h-12`}
  ${({ noTopMargin }) => (noTopMargin ? tw`mt-0 mr-2` : tw`mx-auto mt-2`)}
`
const PopoverGameTextLink = styled(Link)`
  ${tw`font-bold text-base text-center hover:underline`}
`
const PopoverGameText = styled.div`
  ${tw`font-bold text-base text-center text-ellipsis overflow-hidden`}
  width: ${({ width }) => `${width}px`};
`
const PopoverGameTime = styled.div`
  ${tw`text-center text-sm relative`}
`
const PopoverLocation = styled.div`
  ${tw`text-center text-xs`}
`
const CompleteGameContainer = styled.div`
  ${tw`flex flex-col mt-2 mx-1`}
`
const TeamContainer = styled.div`
  ${tw`flex flex-row items-center justify-center`}
`
const ScoreContainer = styled.div`
  ${tw`font-bold`}
`
const PopoverActionSvg = styled.img`
  ${tw`w-4 h-4 absolute top-1.5 left-6`}
`
const GameContainerLink = styled(Link)`
  ${tw`flex flex-col w-full flex-1 h-full relative p-1`}
  background-color: ${props => props.backgroundColor};
  color: ${props => getContrastText(props.backgroundColor)};
  ${({ striped, backgroundColor, promoColor }) =>
    striped &&
    `background: repeating-linear-gradient(135deg, ${promoColor},${promoColor} 10px,${backgroundColor} 10px,${backgroundColor} 20px);`}
`

const ActionSvg = styled.img`
  ${tw`w-4 h-4 absolute left-6 top-1.5`}
`
const DateContainer = styled.div`
  ${tw`absolute left-1 top-1 text-sm`}
  color: ${props => props.color};
`

const CalendarGameItem = ({
  backgroundColor,
  striped,
  promoColor,
  opponentTeam,
  slug,
  isHomeGame,
  isCompletedGame,
  formattedTime,
  game,
  color,
  displayDate,
  homeTeamDoc,
  awayTeamDoc,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const getPosition = props => {
    const { childRect, parentRect } = props
    return {
      top: childRect.top - parentRect.top - 60,
      left: childRect.left - parentRect.left - 60,
    }
  }
  const { promoGame, tv, radio, promoDescriptions, stats, showBuyTicket } =
    game.node.frontmatter
  const { score } = useStats({ inningStats: stats.inningStats })
  const { location } = homeTeamDoc.node.frontmatter
  return (
    <Popover
      isOpen={isOpen}
      contentLocation={getPosition}
      reposition={true}
      content={
        <PopOverContent onMouseLeave={e => setIsOpen(false)}>
          <GameContainerWrapper
            backgroundColor={backgroundColor}
            striped={striped}
            promoColor={promoColor}
            slug={slug}
            isCompletedGame={isCompletedGame}
          >
            {promoGame && (
              <PopoverActionSvg src="/img/svg/asterisk.svg" alt="promo_game" />
            )}
            <DateContainer color={color}>{displayDate}</DateContainer>
            {isCompletedGame ? (
              <CompleteGameContainer>
                <TeamContainer>
                  <PopoverGameText width={100}>
                    {awayTeamDoc.node.frontmatter.name}
                  </PopoverGameText>
                  <PopoverLogoContainer noTopMargin={true}>
                    <GatsbyImage
                      image={getImage(awayTeamDoc.node.frontmatter.logo)}
                      alt="awayteam"
                      style={{ width: "100%", height: "100%" }}
                      objectFit="contain"
                    />
                  </PopoverLogoContainer>
                  <ScoreContainer>{score.away}</ScoreContainer>
                </TeamContainer>
                <TeamContainer>
                  <PopoverGameText width={100}>
                    {homeTeamDoc.node.frontmatter.name}
                  </PopoverGameText>
                  <PopoverLogoContainer noTopMargin={true}>
                    <GatsbyImage
                      image={getImage(homeTeamDoc.node.frontmatter.logo)}
                      alt="hometeam"
                      style={{ width: "100%", height: "100%" }}
                      objectFit="contain"
                    />
                  </PopoverLogoContainer>
                  <ScoreContainer>{score.home}</ScoreContainer>
                </TeamContainer>
              </CompleteGameContainer>
            ) : (
              <>
                <PopoverLogoContainer>
                  <GatsbyImage
                    image={getImage(opponentTeam.node.frontmatter.logo)}
                    alt="opponent"
                    style={{ width: "100%", height: "100%" }}
                    objectFit="contain"
                  />
                </PopoverLogoContainer>
                <PopoverGameTextLink to={slug}>
                  {isHomeGame ? "vs. " : "@ "}{" "}
                  {opponentTeam.node.frontmatter.name}
                </PopoverGameTextLink>
              </>
            )}
            <PopoverGameTime>{isCompletedGame ? "FINAL" : formattedTime}</PopoverGameTime>
            <PopoverLocation>
              {location.town} - {location.fieldName}
            </PopoverLocation>
            {tv && (
              <MediaContainer>
                <b>TV:</b> {tv}
              </MediaContainer>
            )}
            {radio && (
              <MediaContainer>
                <b>Radio:</b> {radio}
              </MediaContainer>
            )}
            {promoGame && !_.isEmpty(promoDescriptions) && (
              <DescriptionContainer>
                {!_.isEmpty(promoDescriptions) && (
                  <DescriptionHeader>Promotions</DescriptionHeader>
                )}
                <DescriptionList>
                  {(promoDescriptions || []).map((str, idx) => (
                    <DescriptionItem key={idx}>{str}</DescriptionItem>
                  ))}
                </DescriptionList>
              </DescriptionContainer>
            )}
            <GameActionContainer>
              {!isCompletedGame && (isHomeGame || showBuyTicket) && (
                <PopoverGameLink
                  href={game.node.frontmatter.ticketLink || "#"}
                  target="_blank"
                >
                  Buy Tickets
                </PopoverGameLink>
              )}
            </GameActionContainer>
          </GameContainerWrapper>
        </PopOverContent>
      }
    >
      <GameContainer
        backgroundColor={backgroundColor}
        promoColor={promoColor}
        striped={striped}
        onMouseEnter={e => setIsOpen(!isOpen)}
      >
        {promoGame && (
          <ActionSvg src="/img/svg/asterisk.svg" alt="promo_game" />
        )}
        {isCompletedGame ? (
          <CompleteGameContainer>
            <TeamContainer>
              <GameText width={70}>
                {awayTeamDoc.node.frontmatter.name}
              </GameText>
              <LogoContainer noTopMargin={true}>
                <GatsbyImage
                  image={getImage(awayTeamDoc.node.frontmatter.logo)}
                  alt="awayteam"
                  style={{ width: "100%", height: "100%" }}
                  objectFit="contain"
                />
              </LogoContainer>
              <ScoreContainer>{score.away}</ScoreContainer>
            </TeamContainer>
            <TeamContainer>
              <GameText width={70}>
                {homeTeamDoc.node.frontmatter.name}
              </GameText>
              <LogoContainer noTopMargin={true}>
                <GatsbyImage
                  image={getImage(homeTeamDoc.node.frontmatter.logo)}
                  alt="hometeam"
                  style={{ width: "100%", height: "100%" }}
                  objectFit="contain"
                />
              </LogoContainer>
              <ScoreContainer>{score.home}</ScoreContainer>
            </TeamContainer>
          </CompleteGameContainer>
        ) : (
          <>
            <LogoContainer>
              <GatsbyImage
                image={getImage(opponentTeam.node.frontmatter.logo)}
                alt="opponent"
                style={{ width: "100%", height: "100%" }}
                objectFit="contain"
              />
            </LogoContainer>
            <GameTextLink to={slug} width={100}>
              {isHomeGame ? "vs. " : "@ "} {opponentTeam.node.frontmatter.name}
            </GameTextLink>
          </>
        )}
        <GameTime>{isCompletedGame ? 'FINAL' : formattedTime}</GameTime>
        <GameActionContainer>
          {!isCompletedGame && (isHomeGame || showBuyTicket) && (
            <GameLink
              href={game.node.frontmatter.ticketLink || "#"}
              target="_blank"
            >
              Buy Tickets
            </GameLink>
          )}
        </GameActionContainer>
      </GameContainer>
    </Popover>
  )
}

export default CalendarGameItem

const GameContainerWrapper = ({
  backgroundColor,
  striped,
  promoColor,
  isCompletedGame,
  slug,
  children,
}) => {
  if (isCompletedGame) {
    return (
      <GameContainerLink
        to={slug}
        backgroundColor={backgroundColor}
        striped={striped}
        promoColor={promoColor}
      >
        {children}
      </GameContainerLink>
    )
  } else {
    return (
      <GameContainer
        backgroundColor={backgroundColor}
        striped={striped}
        promoColor={promoColor}
      >
        {children}
      </GameContainer>
    )
  }
}
